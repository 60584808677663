
.filters {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 5px 5px;
  }

  .filter {
    font-family: Nunito;
    max-width: 100%;
    display: flex;
    align-items:start;
    flex-direction: column;
    padding: 10px 20px;
    border:  1px solid #ddd;
    font-size: 1rem;
  } 
  
  .filter-header {
    font-weight: 800;
  }

  .room-selection {
    display: flex;
    flex-wrap: wrap;
  }
  
  .room-button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }
  
  .room-button.selected {
    background-color: #669bbc;
    color: #fff;
    border-color: #669bbc;
  }
  
  .price-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .price-input {
    position: relative;
    display: inline-block;
  }
  
  .price-input input {
    padding-right: 20px; 
    max-width: 70px;
    padding: 5px;
    border: 1px solid #ccc;
  }
  
  .price-input .currency-symbol {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .city-selection {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }

  .location-symbol:before {
    font-family: Icons;
    content: "\e8b4";
  }

  .search-input {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
  }
  
  .search-input input {
    padding-right: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    text-align: center;
  }

  .telegram-input {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
  }