
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #669bbc;;
    border-bottom: 1px solid #ddd;
    color: white;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: white;
  }
  
  .menu-icon {
    font-size: 24px;
    cursor: pointer;
  }
  