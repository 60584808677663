
.tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
  }
  
  .tab {
    text-align: center;
    width: inherit;
    padding: 10px 20px;
    cursor: pointer;
    color: #888;
    border-bottom: 2px solid transparent;
  }
  
  .tab.active {
    color: #000;
    border-bottom: 2px solid green;
  }
  